<template>
	<a-space direction="vertical">

		<a-card title="邀请海报">
			<a-space slot="extra"><a-button type="primary" @click="$router.push({name:'invites-create'})">新增</a-button></a-space>
			<a-table :data-source="lists.data" :pagination="{ current: lists.current_page, pageSize: lists.per_page, total: lists.total }" @change="onPageChange" :scroll="{ x: 'max-content' }">
				<a-table-column key="title" title="名称" data-index="title">
					<template slot-scope="text, record">
						{{ record.title }}
					</template>
				</a-table-column>
				<a-table-column key="imgUrl" title="图片" data-index="imgUrl">
					<template slot-scope="text, record">
						<a-avatar shape="square" :size="64" :src="record.imgUrl" @click="onPreview(record.imgUrl)" />
					</template>
				</a-table-column>
				<a-table-column key="ctime" title="发布时间" data-index="ctime">
					<template slot-scope="text, record">
						{{ record.ctime }}
					</template>
				</a-table-column>
				<a-table-column key="sort" title="排序号" data-index="sort">
					<template slot-scope="text, record">
						{{ record.sort }}
					</template>
				</a-table-column>
				<a-table-column key="status" title="状态" data-index="status">
					<template slot-scope="text, record">
						<a-badge :color="['green','red'][record.status]" :text="record.status == 1? '下架':'上架'"></a-badge>
					</template>
				</a-table-column>
				<a-table-column key="action" title="操作" fixed="right">
					<template slot-scope="text, record">
						<a-space>
							<a-button size="small" @click="$router.push({name:'invites-info',params:{id: record.id }})">详情</a-button>
							<a-button size="small" @click="$router.push({name:'invites-edit',params:{id: record.id }})">编辑</a-button>
						</a-space>
					</template>
				</a-table-column>
			</a-table>
		</a-card>
	</a-space>
</template>

<script>
	import moment from 'moment';
	import { ROLES } from '@/utils/constants.js';
	import { parseQuery, numberFormat } from '@/utils';
	const formatter = 'YYYY-MM-DD';

	const DEFAULT_QUERY = {
		page: 1,
		size: 10
	};

	export default {
		data() {
			return {
				products: [],
				query: Object.assign({}, DEFAULT_QUERY),
				lists: {
					data: [],
					current_page: 1,
					per_page: 10,
					total: 0
				}
			};
		},
		async mounted() {
			this.getInvites();
		},
		filters: {
			numberFormat
		},
		methods: {
			async getInvites() {
				const response = await this.$api.get('/invite_list', {
					params: this.query
				});
				if (response && response.code == 200) {
					response.data.total = parseInt(response.data.total);
					this.lists = Object.assign(this.lists, response.data);
				}
			},
			async onPageChange(pagination) {
				this.query.page = pagination.current;
				this.getInvites();
			},
			async onSearch() {
				this.query.page = 1;
				this.getInvites();
			},
			async onReset() {
				this.query = Object.assign({}, DEFAULT_QUERY);
				this.getInvites();
			}
		}
	};
</script>